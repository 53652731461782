import styled from "styled-components"
import { fonts } from "../../../utils/styles"

export const ApmWrapper = styled.section`
  background-color: #3B5CA7;
  /* border-radius: 10px; */
  .image-section {
    height: 200px;
    width: 50%;
  }
  h2 {
    font-family: ${fonts.nunito};
  }
`
