import React from "react"
import { ConversionSectionWrapper } from "./ConversionSection.styles"
import { Container, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
const ConversionSection = () => {
  return (
    <ConversionSectionWrapper className="py-5 px-4 px-lg-0">
      <Container className="py-5">
        <HeadingWithBg className="surcharge-solution-heading" text="APM" />
        <Row className="grid">
          <Col>
          <h2 className="h1 font-weight-bold secondary-blue-2-text my-5 nunito">
            The key here is conversion
          </h2>
          <p>
            APEXX currently has an ever expanding suite of over{" "}
            <span className="font-weight-bold">
              120 integrated 'Alternative Payment Methods'.
            </span>{" "}
            In a commercial environment where cash and card no longer simply
            dominate the payments landscape it is essential for enterprise
            merchants to have a thorough understanding of which APMs are popular
            in which of their key markets, and to have a gateway that is
            technically capable of giving them access to all of the methods they
            need instantly.
          </p>
          <p>
            Customers are more likely to go through with a purchase using a
            payment methods they are familiar with and like using. Consequently
            it is becoming ever more vital for merchants to include popular new
            payment methods in their payments acceptance setup.
          </p>
          </Col>
        </Row>
      </Container>
    </ConversionSectionWrapper>
  )
}

export default ConversionSection
