import React from "react"
import { ApmWrapper } from "./ApmDescription.styles"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"

const ApmDescription = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "apm/apm_important" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges
  return (
    <ApmWrapper>
      <Container className="py-5 px-4 px-lg-0 text-center ">
        <h2 className="h1 font-weight-bold text-picton-blue my-5 nunito">
          Why are APM’s important?
        </h2>
        <p className="text-white">
          If your business has a thorough understanding of the types of payment
          methods it's customers like to use in each of its major markets the
          uptick in conversion of sales can be considerable. In terms of the
          anthropology of payments we do not pay the same the world over and the
          forward-thinking enterprise merchant will alway be looking to
          integrate more locally trusted payment methods to drive their sales.
        </p>
        <div className="d-flex justify-content-center">

        <HeadingWithBg variant="green" text="Example" className="secondary-blue-2-text my-4 " />
        </div>
        <p className="text-white">
          Digital/mobile wallets Alipay and WeChat Pay dominate in China, taking
          48% of point of sales in 2019. The local dominance is followed by a
          shortlist of competitors outside China like Apple Pay, Google Pay,
          Samsung Pay and Amazon Pay. Mobile and digital wallet adoption is
          projected to keep evolving through 2023 out of the declining users’
          reliance on cash and card-based payments. The digital payment
          revolution is in motion and fast with the technology penetration and
          influence on younger generations. China, for instance, has
          significantly younger populations than Europe and the United States.
          As of end-2018, around 73% of Chinese internet users paid for their
          transactions with online payments services, an 18% increase on the
          previous decade.
        </p>
        <Row className="pt-lg-4">
          <Col xs={6} md={{span:3,offset:3}} className="p-lg-5">
            <Img fluid={images[0] && images[0].node.childImageSharp.fluid} />
          </Col>
          <Col xs={6} md={3} className="p-lg-5 ">
            <Img fluid={images[1] && images[1].node.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </ApmWrapper>
  )
}

export default ApmDescription
