import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/APMPage/HeroSection/HeroSection"
import ConversionSection from "../components/APMPage/ConversionSection/ConversionSection"
import ApmDescription from "../components/APMPage/ApmDescription/ApmDescription"

import PayLaterSection from "../components/APMPage/PayLaterSection/PayLaterSection"
import SEO from "../components/Global/SEO/seo"

const APMPage = () => {
  return (
    <Layout headerAbsolute headerTransparent whiteToggler textlight logoDark={false}>
      <SEO page="apm" />
      <HeroSection />
      <ConversionSection />
      <ApmDescription />
    </Layout>
  )
}

export default APMPage
