import React from "react"
import { HeroWrapper } from "./HeroSection.styles"
import { Row, Col, Container } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import HeroImage from "./HeroImage"
import { navigateTo } from "../../../utils/hooks"

const HeroSection = () => {
  return (
    <HeroWrapper Tag="section" className="primary-blue-background">
      <Container
        className="py-5 d-flex flex-column justify-content-around h-100"
        fluid
      >
        <Row>
          <Col
            className="d-flex flex-column justify-content-center align-items-baseline mr-5"
            lg={{ offset: 1, span: 5 }}
          >
            <div className="mb-4 mt-5 mt-lg-0">
              <h1 className="blue-aqua">APMs</h1>
              <h2 className="text-white header-subs nunito">
                The Anthropology of Payment Methods
              </h2>
            </div>
            <p className="mb-4">
              Enterprise businesses need a complete understanding of how their
              customers want to pay in their major markets in order to optimise
              their payments setup.
            </p>
            <ApButton
              variant="greenTwo"
              className="px-5 mt-2 explore-button"
              role="link"
              onClick={(e) => navigateTo(e, "contactUs")}
            >
              Explore
            </ApButton>
          </Col>
          <Col className="d-none d-lg-block mt-5 ml-5">
            <HeroImage />
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  )
}

export default HeroSection
