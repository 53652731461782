import styled from "styled-components"

export const HeroWrapper = styled.section`
  @media (min-width: 992px) {
    height: 100vh;
    .header-subs {
      font-size: 2.8rem;
    }
  }
  width: 100%;
  h1 {
    font-size: 2rem;
    @media (min-width: 992px) {
      font-size: 3.2rem;
    }
  }
  svg {
    min-width: 60px;
  }

  p,
  span {
    color: #edf0f2;
    span {
      font-weight: bold;
    }
  }
  .product-image {
    width: 230px;
    &:last-child {
      width: 200px;
    }
  }
  .explore-button {
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`
